<template>
  <div class=" bg-white -mt-4 ">
    <div class="cust_card p-2 m-2 ">
          <div class="flex items-center">
            <p class="heading-3 text-primary cursor-pointer" @click="$router.push({name: 'ProfileDetail'})">Setting</p>
            <p class="heading-3 text-gray3 px-3">></p>
            <p class="heading-3 text-primary cursor-pointer" @click="$router.push({name: 'UserList'})">User List</p>
            <p class="heading-3 text-gray3 px-3">></p>
            <p class="heading-3 text-gray4" >User Detail</p>
          </div>
      </div>
    <div wrap class="flex justify-center -mt-1">
      <div class="px-0 w-7/12 bg-white gap-4 p-2 pt-0">
        <div class="mb-2 mt-2" v-if="staffDetailObj !== null">
          <div class="box col-span-12 ">
            <div class="p-2 cust_card">
              <span class="flex items-center relative justify-between" >
                <!-- <span @click="$router.push({name: 'UserList'})" class="cursor-pointer pt-1"><i class="fas fa-arrow-left h-5 w-5"></i></span> -->
                <div class="heading-2 text-text2 font-semibold pl-3">
                  {{ staffDetailObj.staffFirstName }} {{ staffDetailObj.staffLastName }} <span class="heading-5" :class="staffDetailObj.isActive ? 'text-success' : 'text-error'">({{staffDetailObj.isActive ? 'Active' : 'Inactive'}})</span>
                </div>
                <div>
                  <span @click="editStaff()" class="cursor-pointer pl-3">
                    <i class="cursor-pointer fas fa-edit text-gray-600 heading-2"></i>
                  </span>
                </div>
              </span>
            </div>
            <div class="divider my-0"></div>
              <div class="p-2 cust_card">
                <div wrap class="grid grid-cols-12 relative">
                <div class="text-center py-4 box col-span-3 self-center justify-self-center" style="position: relative;">
                  <div class="rounded-full border border-gray-50" dark style="height: 100px; min-width: 100px; width: 100px;">
                    <img class="rounded-full" :src="profileImage"  v-if="profileImage !== ''">
                    <img class="rounded-full" style="height: 105px; min-width: 105px; width: 105px; " src="@/assets/images/avatar.jpg"  v-if="profileImage === ''">
                  </div>
                  <div class=" flex justify-center -mt-4">
                    <button @click="showProfileImageCropper = true" class="shadow-xl border border-gray-100 rounded-lg " style="width: 70px;background-color: #ffffffe0;">
                      <i class="fas fa-cloud-upload text-gray-800" small></i>
                    </button>
                  </div>
                </div>
                <div class="p-3 box col-span-8 self-center">
                  <div wrap class="heading-4">
                    <div class="flex pb-1">
                      <p class=" text-text2 w-32">Phone:</p>
                      <p class="text-black" v-if="staffDetailObj.staffMobileNumber !== ''"> {{ staffDetailObj.staffMobileNumber | phone }}</p>
                      <p class="text-black" v-else>---</p>
                    </div>
                    <div class="flex pb-1">
                      <p class="text-text2 w-32">Email:</p>
                      <p class="text-black" v-if="staffDetailObj.staffEmail !== ''"> {{ staffDetailObj.staffEmail}}</p>
                      <p class="text-black" v-else>---</p>
                    </div>
                    <div class="flex pb-1">
                      <p class="text-text2 w-32">Password:</p>
                      <p class="text-black">******</p>
                    </div>
                  </div>
                </div>
                <div class="absolute right-0" v-if="staffDetailObj.isUserBlocked" >
                  <div class="heading-5 text-error">system locked user after 4 consecutive failures</div>
                  <button class="shadow-xl border border-gray-100 rounded-lg p-1 px-2 bg-error text-white hover:opacity-80 fontChanges heading-6 absolute right-0" @click="unblckUser()">
                      Unblock User
                    </button>
                </div>
              </div>
            </div>
            <div class="cust_card p-3 mt-2" v-if="staffDetailObj.isAdmin">
              <div>
                <div class="heading-3 text-text1">This user is admin</div>
              </div>
            </div>
            <div class="cust_card p-2 mt-2" v-if="!staffDetailObj.isAdmin">
              <div>
                <div class="flex items-center justify-between" v-if="staffDetailObj.analyticMenuPermission !== null">
                  <div class="heading-2 text-text1 ml-3"><span>Permissions:</span></div>
                  <div class="" >
                    <span v-if="!expandList" @click="expandAll(); expandList = true" class="text-primary cursor-pointer">Expand all</span>
                    <span v-if="expandList" @click="collapseAll(); expandList = false" class="text-primary cursor-pointer">Collapse all</span>
                  </div>
                </div>
                <div v-if="staffDetailObj.analyticMenuPermission === null" class="heading-3 text-text1 ml-2">User does not have permission to access any menu</div>
                <div class="rounded h-full bg-white" :style="`max-height` + cardHeight + `px;overflow: auto; !important`">
                  <div v-for="(entity, entityIndex) in staffDetailObj.analyticMenuPermission" :key="entityIndex">
                    <div class="p-2 cust_card m-3">
                      <div class="flex heading-4 text-text1 items-center cursor-pointer" @click="openPanel(entityIndex)">
                          <span v-if="entity.show"><upAngleSvg :color="'black'" class="mr-3" :width="'20px'" :height="'20px'"/></span>
                          <span v-else><downAngleSvg class="mr-3" :color="'black'" :width="'20px'" :height="'20px'"/></span>
                          {{entity.mainMenuName}}
                      </div>
                        <div v-if="entity.show" class="p-2 pl-6">
                          <div v-for="(data, dataIndex) in entity.subMenuList" :key="dataIndex" class="p-1 fix_width">
                              <div class="flex items-center mr-10 w-full">
                                <li class="heading-4 text-text1">{{data.subMenuName}}</li>
                              </div>
                          </div>
                        </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="showProfileImageCropper">
        <div class="bg-white shadow-xl border-l-2 cust_card border-gray-100"  style="position: fixed;height: 100%;width: 100%;min-width: 80px;z-index: 9000000;top: 0;overflow-x: hidden;transition: 0.5s;border-radius: 0px !important;right:0;">
          <ProfileImageModal :entityName="`${staffDetailObj.staffFirstName} ${staffDetailObj.staffLastName}`" :ratio="1" :imageToCrop="profileImage" :title="'Profile Image'" v-if="showProfileImageCropper"/>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import ProfileImageModal from '@/View/components/clipper.vue'
import downAngleSvg from '@/assets/images/svg/downAngleSvg.vue'
import upAngleSvg from '@/assets/images/svg/upAngleSvg.vue'
// import ProfileImageModal from '@/View/components/clipper.vue'
import API from '@/api/App.js'
export default {
  components: {
    ProfileImageModal,
    downAngleSvg,
    upAngleSvg
    // ProfileImageModal,
  },
  data () {
    return {
      cardHeight: window.innerHeight - 410,
      expandList: true,
      staffDetailObj: null,
      profileImage: '',
      showProfileImageCropper: false,
      cardView: false
    }
  },
  mounted () {
    this.$root.$on('closeProfileDialog', (data) => {
      if (data.isUpdated) {
        this.profileImageUpload(data.file)
      }
      this.showProfileImageCropper = false
    })
    this.getEmployeeDetails()
  },
  methods: {
    unblckUser () {
      this.$store.dispatch('SetLoader', {status: true, message: 'Loading ...'})
      API.UnblockUser(
        this.staffDetailObj.staffId,
        response => {
          this.getEmployeeDetails()
          this.$store.dispatch('SetLoader', {status: false, message: 'Loading ...'})
          this.$store.dispatch('SetAlert', {showAlert: true, message: response.message, color: 'success'})
        },
        error => {
          this.$store.dispatch('SetLoader', {status: false, message: 'Loading ...'})
          this.$store.dispatch('SetAlert', {showAlert: true, message: error.message, color: 'error'})
        }
      )
    },
    profileImageUpload (file) {
      this.$store.dispatch('SetLoader', {status: true, message: 'Loading ...'})
      API.profileUpdate(
        this.staffDetailObj.staffId,
        file,
        response => {
          // this.showProfileImageCropper = true
          this.$store.dispatch('SetLoader', {status: false, message: 'Loading ...'})
          this.profileImage = URL.createObjectURL(file)
          this.$store.dispatch('SetAlert', {showAlert: true, message: response.message, color: 'success'})
        },
        error => {
          this.$store.dispatch('SetLoader', {status: false, message: 'Loading ...'})
          this.$store.dispatch('SetAlert', {showAlert: true, message: error.message, color: 'error'})
        }
      )
    },
    editStaff () {
      this.$router.push({name: 'editStaff', params: {staffId: this.staffDetailObj.staffId}})
    },
    openPanel (entityIndex) {
      if (this.staffDetailObj.analyticMenuPermission[entityIndex].show === false) {
        this.staffDetailObj.analyticMenuPermission[entityIndex].show = true
      } else {
        this.staffDetailObj.analyticMenuPermission[entityIndex].show = false
      }
    },
    resizeScreenHandler () {
      if (window.innerWidth < 940) {
        this.cardView = true
      } else {
        this.cardView = false
      }
    },
    expandAll () {
      this.expandList = true
      for (let index = 0; index < this.staffDetailObj.analyticMenuPermission.length; index++) {
        this.staffDetailObj.analyticMenuPermission[index].show = true
      }
    },
    collapseAll () {
      this.expandList = false
      for (let index = 0; index < this.staffDetailObj.analyticMenuPermission.length; index++) {
        this.staffDetailObj.analyticMenuPermission[index].show = false
      }
    },
    getEmployeeDetails () {
      this.profileImage = ''
      if (this.$route.params.staffId !== undefined) {
        this.$store.dispatch('SetLoader', {status: true, message: 'Loading...'})
        API.getStaffDetail(
          parseInt(this.$route.params.staffId),
          response => {
            this.staffDetailObj = response.Data === null ? null : response.Data
            document.title = `Staff: ${ this.staffDetailObj.staffFirstName} ${this.staffDetailObj.staffLastName} `
            console.log('this.staffDetailObj', this.staffDetailObj)
            if (this.staffDetailObj.profileImagePath.medium !== '') {
              this.profileImage = this.staffDetailObj.profileImagePath.medium
            }
            this.$store.dispatch('SetLoader', {status: false, message: 'Loading...'})
          },
          error => {
            this.$store.dispatch('SetLoader', {status: false, message: 'Loading...'})
            this.$store.dispatch('SetAlert', {showAlert: true, message: error.message, color: 'error'})
          }
        )
      }
    }
  },
  watch: {
  },
  beforeDestroy () {
    this.$root.$off('closeProfileDialog')
  }
}
</script>
